import React from "react";

import SiteLayout from "@layouts/site-layout";
import HeaderEnrollNow from "@components/ExploreMore/customComponents/HeaderEnrollNow";
import AppliedLearningProject from "@components/ExploreMore/AppliedLearningProject";
import ColorfulBox from "@components/ExploreMore/customComponents/ColorfulBox";
import BuildJob from "@components/ExploreMore/customComponents/BuildJob";
import Skills from "@components/ExploreMore/customComponents/Skills";
import Certificate from "@components/ExploreMore/customComponents/Certificate";
import WhatyouWillLearn from "@components/ExploreMore/customComponents/WhatyouWillLearn";

const data = {
  boxDetails: {
    title: "Circular Design & Sustainability",
    text:
      "Develop strategies to build sustainable products and circular supply chains to build profitability through scientific design practices.",
    rating: "4.9/5",
    totalRating: "( 91,664 ratings )",
    enroll: "1,223,890 already enrolled",
    started: "Start from 15 april",
  },

  colorfulBoxContent: [
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "4 Months",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "English",
      contentTxt: "Under 10 hours study of study a week.",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "Beginner",
      contentTxt: "No prior experience required.",
    },
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "100% Self-Paced",
      contentTxt: "No prior experience required.",
    },
  ],

  jobTitles: [
    { contentTxt: "EGS Analyst" },
    { contentTxt: "ESG Consultant" },
    { contentTxt: "Circular Design Consultant" },
    { contentTxt: "Sustainability Consultant" },
    { contentTxt: "Environmentalist" },
    { contentTxt: "Environmental Consultant" },
  ],

  jobTitlesBox: [
    {
      bgColor: "#DFD4FF",
      txtColor: "#7D7D7D",
      headerColor: "#2A0D7E",
      headerTxt: "120,000",
      contentTxt: "in-demand Job Listings",
    },
    {
      bgColor: "#FBFDD1",
      txtColor: "#7D7D7D",
      headerColor: "#545706",
      headerTxt: "20 LPA+",
      contentTxt: "Median salary",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#7D7D7D",
      headerColor: "#0A662A",
      headerTxt: "G20",
      contentTxt: "Presidencyship with India",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#7D7D7D",
      headerColor: "#6F0755",
      headerTxt: "8+",
      contentTxt: "Hands on projects.",
    },
  ],

  skills: [
    {
      bgColor: "#FCFFA6",
      txtColor: "#9B9F19",
      contentTxt: "Ciruclarity",
    },
    {
      bgColor: "#D0FDE0",
      txtColor: "#0D8036",
      contentTxt: "Circular Design",
    },
    {
      bgColor: "#FAE0F3",
      txtColor: "#ED77D0",
      contentTxt: "Reporting",
    },
    {
      bgColor: "#E0E9FA",
      txtColor: "#4545AF",
      contentTxt: "ESG",
    },
    {
      bgColor: "#FCFFA6",
      txtColor: "#9B9F19",
      contentTxt: "Ghg Frameworks",
    },
  ],

  aboutProfessionalCertificate: [
    "Introducing our comprehensive course on Circular Design and Sustainability! This course is designed for individuals who are passionate about making a positive impact on the environment through innovative and sustainable design practices.",
    "Throughout the course, you will learn how to design products and systems that promote a circular economy, where materials are kept in use for as long as possible and waste is minimized. You will also gain a deep understanding of the principles of sustainability and how to integrate them into your design process.",
  ],

  whatYouWillLearn: [
    "Introducing our comprehensive course on Circular Design and Sustainability! This course is designed for individuals who are passionate about making a positive impact on the environment through innovative and sustainable design practices.",
    "Throughout the course, you will learn how to design products and systems that promote a circular economy, where materials are kept in use for as long as possible and waste is minimized. You will also gain a deep understanding of the principles of sustainability and how to integrate them into your design process.",
    "Our experienced instructors will guide you through a range of topics, including life cycle assessment, eco-design, material selection, and design for disassembly. You will also have the opportunity to engage in hands-on activities and projects, allowing you to apply your knowledge in a practical setting.",
    "By the end of the course, you will have developed a comprehensive understanding of circular design and sustainability, and you will be equipped with the skills and knowledge necessary to create innovative, environmentally friendly products and systems.",
    "Join us on this exciting journey towards a more sustainable future!",
  ],

  appliedLearningProject:
    "What is an applied learning project? It's a project-based learning experience that allows students to take an active role in solving real-world problems. Through our program, students will work closely with faculty, industry experts, and community partners to identify a problem or challenge, develop a plan of action, and implement a solution.",

  benefitsForStudents: [
    {
      headerTxt: "Practical experience :",
      contentTxt:
        "Students will gain practical, hands-on experience that is directly applicable to their future careers.",
    },
    {
      headerTxt: "Problem-solving skills :",
      contentTxt:
        "Through our program, students will develop critical thinking and problem-solving skills that will help them succeed in their future careers.",
    },
    {
      headerTxt: "Networking opportunities :",
      contentTxt:
        "Our program offers students the opportunity to network with industry experts and community partners, building valuable connections for their future careers",
    },
    {
      headerTxt: "Personal growth :",
      contentTxt:
        "Through our program, students will develop their interpersonal skills, teamwork abilities, and leadership capabilities, all of which are essential for success in any career.",
    },
    {
      headerTxt: "Resume building :",
      contentTxt:
        "Participating in an applied learning project is a valuable addition to any student's resume, demonstrating their commitment to experiential learning and problem-solving.",
    },
  ],

  startLearningToday: [
    { contentTxt: "Comprehensive knowledge" },
    { contentTxt: "Hands-on experience" },
    { contentTxt: "In-demand skills" },
    { contentTxt: "Creative Freedom" },
    { contentTxt: "Flexibility" },
    { contentTxt: "Community" },
  ],
};

const index = () => {
  return (
    <SiteLayout>
      <div className="home_middle_sec top_margin_comn pt-5 align-items-center">
        <div className="container px-3 row pb-3 pb-md-5">
          <div className="col-12 col-md-6">
            <HeaderEnrollNow
              headerText={data.boxDetails.title}
              subtitle={data.boxDetails.text}
              rating={data.boxDetails.rating}
              totalRating={data.boxDetails.totalRating}
              enroll={data.boxDetails.enroll}
              started={data.boxDetails.started}
            />
          </div>
          <div className="col-12 col-md-6 header_colorfulBox_container py-3">
            {data.colorfulBoxContent.map((item) => (
              <ColorfulBox item={item} />
            ))}
          </div>
        </div>
        <BuildJob jobTitles={data.jobTitles} jobTitlesBox={data.jobTitlesBox} />
        <Skills skills={data.skills} />
        <Certificate />
        <AboutProfessionalCertificate />
        <WhatyouWillLearn whatYouWillLearn={data.whatYouWillLearn} />
        <AppliedLearningProject />
      </div>
    </SiteLayout>
  );
};

export default index;

const AboutProfessionalCertificate = () => {
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <span className="headerText">About this Professional Certificate</span>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="contentText text-center">
          Introducing our comprehensive course on{" "}
          <span className="highlight_text">
            {" "}
            Circular Design and Sustainability!
          </span>{" "}
          This course is designed for individuals who are passionate about
          making a positive impact on the environment through
          <span className="highlight_text">
            innovative and sustainable design practices.
          </span>
        </p>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="contentText text-center">
          Throughout the course, you will learn how to{" "}
          <span className="highlight_text"> design products </span> and systems
          that promote a{" "}
          <span className="highlight_text"> circular economy,</span> where
          materials are kept in use for as long as possible and waste is
          minimized. You will also gain a deep understanding of the{" "}
          <span className="highlight_text"> principles of sustainability </span>{" "}
          and how to
          <span className="highlight_text">
            integrate them into your design process.
          </span>
        </p>
      </div>
    </div>
  );
};
